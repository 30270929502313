import qs from 'qs';
import { enqueueSnackbar } from 'notistack';
import { authService } from '../modules/auth';
import { HTTP_ERROR_MESSAGES } from './constants';
import { config } from '../config';

export type ReqestOption = {
  path: string;
  baseUrl?: string;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  headers?: {
    [key: string]: string;
  };
  body?: any;
  contentType?: 'json';
  params?: {
    [key: string]: string;
  };
  withAuth?: boolean;
  silent?: boolean;
};

export type RequestResponse = {
  ok: boolean;
  statusCode?: number;
  headers?: Headers;
  data?: any;
  errorMessage?: string;
};

export const request = async (options: ReqestOption) => {
  let {
    baseUrl = config.apiBaseUrl,
    path,
    method = 'GET',
    headers,
    body,
    contentType = 'json',
    params,
    withAuth = true,
    silent = false,
  } = options;

  let requestBody = null;
  if (body && contentType === 'json') {
    requestBody = JSON.stringify(body);
    headers = {
      ...headers,
      'Content-Type': 'application/json',
    };
  }

  let url = path;
  if (baseUrl) {
    url = baseUrl + url;
  }
  if (params) {
    url = url + (url.includes('?') ? '' : '?') + qs.stringify(params);
  }

  if (withAuth) {
    try {
      const accessToken = await authService.getValidAccessToken();
      headers = {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      };
    } catch (e) {
      const result: RequestResponse = {
        ok: false,
        data: null,
        errorMessage: 'Unauthorized',
      };
      if (!silent) {
        enqueueSnackbar(result.errorMessage!, { variant: 'error' });
      }
      return result;
    }
  }

  const requestData: RequestInit = {
    headers,
    method,
    body: requestBody,
  };

  let response: Partial<Response> & { ok: boolean } = {
    ok: false,
  };
  try {
    response = await fetch(url, requestData);
  } catch (e) {
    // pass
  }

  const result: RequestResponse = {
    ok: response.ok,
    statusCode: response.status,
    headers: response.headers,
    data: null,
  };

  const blobTypes = ['image/png', 'application/pdf', 'text/csv'];

  if (result.headers && response.json) {
    const bodyType = result.headers.get('content-type');

    if (bodyType === 'application/json') {
      result.data = await response.json();
    }

    if (bodyType && blobTypes.includes(bodyType)) {
      if (response.blob) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        result.data = url;
      }
    }
  }

  if (!result.ok) {
    if (result.data) {
      if (result.data.message) {
        result.errorMessage = result.data.message;
      } else if (result.data.detail) {
        result.errorMessage = result.data.detail;
      } else if (typeof result.data === 'object') {
        const values = Object.values(result.data);
        if (values.length > 0) {
          const firstValue = values[0];
          result.errorMessage = Array.isArray(firstValue)
            ? firstValue[0]
            : firstValue;
        }
      } else {
        result.errorMessage = JSON.stringify(result.data);
      }
    } else if (result.statusCode === null || result.statusCode === undefined) {
      result.errorMessage = 'Connection failed';
    } else if (result.statusCode in HTTP_ERROR_MESSAGES) {
      result.errorMessage = HTTP_ERROR_MESSAGES[result.statusCode];
    } else {
      result.errorMessage = 'Unknown error';
    }

    if (!silent) {
      enqueueSnackbar(result.errorMessage || 'Unknown error', {
        variant: 'error',
      });
    }
  }

  return result;
};
