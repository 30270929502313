export interface ListPaginationState {
  page: number;
  size: number;
}

export interface ListFiltersState {
  [key: string]: any;
}

export enum OrderingDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export interface ListOrderingState {
  field: string;
  direction: OrderingDirection;
}

export interface ListData<Record = any> {
  data: Array<Record> | null;
  count?: number | null;
  next?: number | null;
  url?: string | null;
}

export interface ListDataRequestParams {
  pagination?: ListPaginationState | null;
  ordering?: ListOrderingState | null;
  filters?: ListFiltersState | null;
}

export type ListDataRequest = (
  params?: ListDataRequestParams
) => Promise<ListData>;
