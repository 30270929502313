import { FC, useContext, useRef } from 'react';
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
  GridCallbackDetails,
  GridSortModel,
  GridFilterModel,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import {
  DataGridContainer,
  tableStyles,
  NoRowsOverlayContainer,
} from './styles';
import {
  ListData,
  ListContext,
  ListOrderingState,
  OrderingDirection,
  SearchBar,
} from '../..';
import { Typography } from '@mui/material';
import { UserModel } from '../../../account';
import { ScanModel } from '../../../scan';
import { PatientModel } from '../../../patient/network';
import { CoordinatorType } from '../../../coordinator/model';

export type CombinedTableModel =
  | UserModel
  | ScanModel
  | CoordinatorType
  | PatientModel;

interface DataGridTableProps {
  loading: boolean;
  data: ListData['data'] | null;
  columns: GridColDef<CombinedTableModel>[];
  height?: string;
  tableType: 'patient' | 'record' | 'coordinator' | 'signup';
  onRowClick: (item: CombinedTableModel) => void;
}

const dummyRows = [{ id: 1 }, { id: 2 }, { id: 3 }];

export const DataGridTable: FC<DataGridTableProps> = (props) => {
  const { setPagination, setOrdering, count, setFilters } =
    useContext(ListContext);
  const { loading, data, columns, height, tableType, onRowClick } = props;
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const onPaginationModelChange = (
    data: GridPaginationModel,
    _details: GridCallbackDetails
  ) => {
    setPagination({
      page: data.page + 1,
      size: data.pageSize,
    });
  };

  const onSortingModelChange = (
    data: GridSortModel,
    _details: GridCallbackDetails
  ) => {
    if (data.length > 0) {
      const newOrdering: ListOrderingState = {
        field: data[0].field,
        direction:
          data[0].sort === 'asc'
            ? OrderingDirection.Asc
            : OrderingDirection.Desc,
      };
      setOrdering(newOrdering);
    }
  };

  const noRowsOverlayDisplay = () => (
    <NoRowsOverlayContainer>
      <Typography variant='h6' color='textSecondary'>
        No {tableType}s found...
      </Typography>
    </NoRowsOverlayContainer>
  );

  const onFilterModelChange = (filterModel: GridFilterModel) => {
    setFilters((prevModel) => ({
      ...prevModel,
      ...filterModel,
    }));
  };

  return (
    <>
      <DataGridContainer
        loading={loading}
        ref={tableContainerRef}
        style={{ height: data && data.length > 0 ? 'unset' : 325 }}
      >
        {data ? (
          <DataGridPro
            filterMode='server'
            onFilterModelChange={onFilterModelChange}
            slots={{
              noRowsOverlay: noRowsOverlayDisplay,
              // TODO: add filters in backend for sign up requests
              // filtering not yet implemented on sign up request route
              toolbar: tableType === 'signup' ? null : CustomToolBar,
            }}
            style={tableStyles({ height })}
            sx={{
              '& .MuiDataGrid-columnHeader': {
                // backgroundColor: 'rgba(246, 246, 246, 0.38)',
                backgroundColor: 'white',
              },
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within ': {
                outline: 'none !important',
              },
              '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within ': {
                outline: 'none !important',
              },
            }}
            disableColumnMenu
            rows={data}
            columns={columns}
            onRowClick={(params) =>
              onRowClick(params.row as CombinedTableModel)
            }
            onPaginationModelChange={onPaginationModelChange}
            onSortModelChange={onSortingModelChange}
            sortingMode='server'
            pagination
            paginationMode='server'
            rowCount={count || 0}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            loading={loading}
          ></DataGridPro>
        ) : (
          <DataGridPro
            loading={true}
            rows={dummyRows}
            columns={[]}
            style={tableStyles({ height })}
            rowCount={3}
            //paginationModel={null}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
          ></DataGridPro>
        )}
      </DataGridContainer>
    </>
  );
};

const CustomToolBar = () => {
  return (
    <GridToolbarContainer
      sx={{
        paddingTop: 5,
        paddingBottom: 5,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {/* TODO: add filters in backend */}
      {/* <div style={{ display: 'flex', gap: 10 }}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </div> */}
      <div>
        <SearchBar />
      </div>
    </GridToolbarContainer>
  );
};
