import { FC } from 'react';
import { Button, Typography } from '@mui/material';
import { Modal, ModalProps } from '../../../ui';
import { Container, ButtonsContainer } from './styles';

export type RemoveFromOrgModalProps = ModalProps & {
  onClose?: (reason?: 'cancel' | 'success') => void;
  patientId: number;
  requestRemovePatientFromOrg: (patient_id: number) => void;
  isLoadingRemovePatientFromOrg: boolean;
};

export const RemoveFromOrgModal: FC<RemoveFromOrgModalProps> = (props) => {
  const {
    open = false,
    onClose,
    patientId,
    requestRemovePatientFromOrg,
    isLoadingRemovePatientFromOrg,
  } = props;
  return (
    <Modal
      open={open}
      onClose={isLoadingRemovePatientFromOrg ? undefined : onClose}
      title='Remove Patient From Organization'
    >
      <Container>
        <Typography align='center'>
          Are you sure you want to remove this patient from your organization?
        </Typography>
        <ButtonsContainer>
          <Button
            variant='containedLight'
            onClick={onClose}
            disabled={isLoadingRemovePatientFromOrg}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={() => requestRemovePatientFromOrg(patientId)}
            disabled={isLoadingRemovePatientFromOrg}
          >
            {isLoadingRemovePatientFromOrg ? 'Removing...' : 'Remove'}
          </Button>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
};
