import { styled, Box } from '@mui/material';

export const OuterTitleContainer = styled(Box)`
  margin-bottom: 1.5vw;
  margin-top: 1.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 1vw;
`;
