import { FC } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { ShowErrorFunc, showErrorOnChange } from '../../utils/finalForms';
import { SelectInput, SelectInputProps } from './SelectInput';

export type SelectInputFFProps = SelectInputProps & {
  name: string;
  fieldProps?: Partial<FieldProps<any, any>>;
	showError?: ShowErrorFunc;
};

export const SelectInputFFWrapper: FC<FieldRenderProps<SelectInputProps>> = (
  props
) => {
  const {
    input: { name, value, type, onChange, onBlur, onFocus, ...restInput },
    meta,
    required,
    helperText,
    showError = showErrorOnChange,
    ...rest
  } = props;

  const { error, submitError } = meta;
	const isError = showError({ meta });

  return (
    <SelectInput
      error={isError}
      helperText={isError ? error || submitError : helperText}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      name={name}
      value={value}
      type={type}
      required={required}
      inputProps={{ required, ...restInput }}
      {...rest}
    />
  );
};

export const SelectInputFF: FC<SelectInputFFProps> = (props) => {
  const { name, type, fieldProps, ...rest } = props;

  return (
    <Field
      name={name}
      type={type}
      render={({ input, meta }) => (
        <SelectInputFFWrapper
          input={input}
          meta={meta}
          name={name}
          type={type}
          {...rest}
        />
      )}
      {...fieldProps}
    />
  );
};
