import { useCallback, useState } from 'react';
import { removePatientFromOrg } from '../network';

export const useRemovePatientFromOrg = (): [
  boolean,
  Error | null,
  boolean | null,
  (patient_id: number) => void
] => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [result, setResult] = useState<boolean | null>(null);

  const request = useCallback(async (patient_id: number) => {
    setIsLoading(true);
    setError(null);

    try {
      await removePatientFromOrg(patient_id);
      setResult(true);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        const error = new Error('Unknown error');
        setError(error);
      }
    }

    setIsLoading(false);
  }, []);

  return [isLoading, error, result, request];
};
