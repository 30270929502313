import { FC, useEffect } from 'react';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { Signin } from '../../components';
import { useAuthContext } from '../../containers';

export const SigninScreen: FC = () => {
  const { setAuthData } = useAuthContext();

  useEffect(() => {
    // check url for access and refresh params. If they exist, setAuthData with those values
    const url = new URL(window?.location?.href);
    const accessToken = url?.searchParams?.get('access');
    const refreshToken = url?.searchParams?.get('refresh');
    if (accessToken && refreshToken) {
      setAuthData({ accessToken, refreshToken });
    }
  }, [setAuthData]);

  return (
    <>
      <DocumentTitle title='Sign-in' />
      <Signin />
    </>
  );
};
