import { ListData, ListDataRequestParams } from '../modules/ui';
import { FeaturesetValues, ScanModel } from '../modules/scan';

import { request } from './request';

export const requestList = async (
  path: string,
  data: ListDataRequestParams = {}
): Promise<ListData> => {
  let params: any = {};
  if (data.pagination) {
    params.page = data.pagination.page;
    params.page_size = data.pagination.size ?? 10;
  }
  if (data.ordering) {
    if (
      data.ordering.field !== 'most_recent_metashape_pip_package_version' &&
      data.ordering.field !==
        'most_recent_mesh_processing_pip_package_version' &&
      data.ordering.field !== 'device_os' &&
      data.ordering.field !== 'app_version'
    ) {
      params.ordering =
        data.ordering.direction === 'asc'
          ? data.ordering.field
          : `-${data.ordering.field}`;
    } else {
      // since pip version ordering is actually a filter in the backend, this is what I had to do to apply that logic to the frontend
      params[`order_${data.ordering.field}`] = data.ordering.direction;
    }
  }

  // this is only applies for the all-scans page
  if (data.filters?.items) {
    // checks if the show_null_cobbs filter is true
    const isShowNullCobbsTrue = Boolean(
      data.filters.items.find(
        (item: { field: string; value: string }) =>
          item.field === 'show_null_cobbs' && item.value === 'true'
      )
    );
    for (const filters of data.filters.items) {
      if (filters.operator === 'isAnyOf' && filters.value) {
        params[filters.field] = filters.value.join(',');
        if (isShowNullCobbsTrue) {
          params[filters.field] = `${params[filters.field]},NULL`;
        }
      } else if (filters.operator === '>=' && filters.value) {
        params[`${filters.field}_min`] = parseInt(filters.value, 10);
        if (isShowNullCobbsTrue) {
          params[`show_null_${filters.field}`] = 'true';
        }
      } else if (filters.operator === '<=' && filters.value) {
        params[`${filters.field}_max`] = parseInt(filters.value, 10);
        if (isShowNullCobbsTrue) {
          params[`show_null_${filters.field}`] = 'true';
        }
      } else if (filters.operator === 'contains' && filters.value) {
        params[`${filters.field}_contains`] = filters.value;
      } else if (filters.operator === 'is' && filters.value) {
        if (filters.field !== 'show_null_cobbs') {
          params[filters.field] = filters.value;
        }
      } else if (filters.operator === 'greater_than' && filters.value) {
        params[`${filters.field}_greater_than`] = filters.value[0];
      }
    }
  } else {
    params = {
      ...params,
      ...data.filters,
    };
  }

  const response = await request({
    path,
    method: 'GET',
    params,
  });

  // Get the Content-Type header from the response
  const contentType = response.headers?.get('Content-Type');

  if (contentType === 'text/csv') {
    return {
      url: response.data,
      data: null,
      count: null,
      next: null,
    };
  }

  if (response.ok) {
    const regex = /page=([^&]+)/;
    let page: number | null = null;

    if (response.data.next) {
      const match = response.data.next.match(regex);
      page = match ? parseInt(match[1], 10) : null;
    }

    return {
      count: response.data.count,
      data: response.data.results,
      next: page,
    };
  }

  throw new Error(response.errorMessage);
};

export const patchRequest = async (
  path: string,
  patchData: object = {}
): Promise<ScanModel | FeaturesetValues> => {
  const response = await request({
    path,
    method: 'PATCH',
    body: patchData,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const retriggerScanRequest = async (
  path: string,
  postData: { id: number }
): Promise<{ Success: 'Processing retriggered successfully' }> => {
  const response = await request({
    path: path,
    method: 'POST',
    body: postData,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const resendInvitationRequest = async (
  path: string,
  postData: { user_id: number }
): Promise<{ Success: 'Invitation email resent successfully' }> => {
  const response = await request({
    path: path,
    method: 'POST',
    body: postData,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const sendNextScanReminderRequest = async (
  path: string,
  postData: { patient_id: number }
): Promise<{ Success: 'Next scan reminder sent successfully' }> => {
  const response = await request({
    path: path,
    method: 'POST',
    body: postData,
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};
