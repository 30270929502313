import { styled } from '@mui/material';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  gap: 1vw;
  margin-top: 2vh;
`;
