import { FC, useState } from 'react';
import { Button } from '@mui/material';
import { Modal, ModalProps } from '../../../ui';
import { CustomDateRangePicker } from '../DateRangePicker';
import { ButtonContainer } from './styles';

export type ExportPatientDataModalProps = ModalProps & {
  onClose?: (reason?: 'cancel' | 'success') => void;
  patientId: string | undefined;
  requestExportPatientData: (
    patient_id: string | undefined,
    startDate: Date,
    endDate: Date
  ) => void;
  isLoadingExportPatientData: boolean;
};

export const ExportPatientDataModal: FC<ExportPatientDataModalProps> = (
  props
) => {
  const {
    open = false,
    onClose,
    patientId,
    requestExportPatientData,
    isLoadingExportPatientData,
  } = props;

  const [dateRange, setDateRange] = useState({
    start: new Date(),
    end: new Date(Date.now() + 24 * 60 * 60 * 1000),
  });

  const handleSubmit = (start: Date, end: Date) => {
    setDateRange({ start, end });
  };

  return (
    <Modal
      open={open}
      onClose={isLoadingExportPatientData ? undefined : onClose}
      title='Export Patient Data'
    >
      <CustomDateRangePicker
        start={dateRange.start}
        end={dateRange.end}
        onSubmit={handleSubmit}
      />
      <ButtonContainer>
        <Button
          variant='contained'
          onClick={() =>
            requestExportPatientData(patientId, dateRange.start, dateRange.end)
          }
          disabled={isLoadingExportPatientData}
        >
          {isLoadingExportPatientData ? 'Exporting...' : 'Export'}
        </Button>
      </ButtonContainer>
    </Modal>
  );
};
