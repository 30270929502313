import { flow, Instance, types } from 'mobx-state-tree';
import { CoordinatorModel } from './model';
import { getEmployeeList } from '../settings/network';

const getAllCoordinators = async () => {
  const list = [];
  let page = 1;
  let hasNextPage = true;
  const maxPages = 100; // prevent infinite loop

  while (hasNextPage && page < maxPages) {
    const response = await getEmployeeList({
      pagination: { size: 100, page },
    });

    if (response) {
      if (response.data) {
        list.push(...response.data);
      }
    }

    hasNextPage = Boolean(response.next);
    page += 1;
  }

  return list;
};

const CoordinatorStore = types
  .model('CoordinatorStore', {
    coordinatorList: types.array(CoordinatorModel),
  })
  .actions((self) => {
    const fetchAllCoordinators = flow(function* () {
      const list = yield getAllCoordinators();

      self.coordinatorList = list;
    });

    return {
      fetchAllCoordinators,
      afterCreate() {
        fetchAllCoordinators();
      },
    };
  });

export type CoordinatorStoreType = Instance<typeof CoordinatorStore>;

let coordinatorStore: CoordinatorStoreType;

export const useCoordinatorStore = () => {
  if (!coordinatorStore) {
    coordinatorStore = CoordinatorStore.create();
  }

  return coordinatorStore;
};
