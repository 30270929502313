import { useCallback, useState } from 'react';
import { exportPatientData } from '../network';

export const useExportPatientData = (): [
  boolean,
  Error | null,
  boolean | null,
  (patient_id: string | undefined, startDate: Date, endDate: Date) => void
] => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [result, setResult] = useState<boolean | null>(null);

  const request = useCallback(
    async (patient_id: string | undefined, startDate: Date, endDate: Date) => {
      setIsLoading(true);
      setError(null);

      const formattedStartDate = startDate.toISOString().split('T')[0];
      const formattedEndDate = endDate.toISOString().split('T')[0];

      try {
        const response = await exportPatientData(
          patient_id,
          formattedStartDate,
          formattedEndDate
        );
        const link = document.createElement('a');
        const filename =
          formattedStartDate && formattedEndDate
            ? `patient_report_${formattedStartDate}_${formattedEndDate}.pdf`
            : 'patient_report.pdf';
        link.href = response;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(response);
        setResult(true);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          const error = new Error('Unknown error');
          setError(error);
        }
      }

      setIsLoading(false);
    },
    []
  );

  return [isLoading, error, result, request];
};
