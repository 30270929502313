import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { ScanStatusCellRenderer, ScanModel } from '../scan';
import { TRowClickFunction } from '../ui/components/DataGridPro/helpers';
import { useListDataRequest, OrderingDirection } from '../ui';
import {
  DataGridPro,
  GridCallbackDetails,
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  useGridApiRef,
  GridFilterModel,
  GridSortModel,
  GridFilterItem,
  getGridNumericOperators,
  GridPanel,
  FilterColumnsArgs,
  GetColumnForNewFilterArgs,
  getGridBooleanOperators,
  getGridStringOperators,
  getGridSingleSelectOperators,
  GridLogicOperator,
  GridColumnVisibilityModel,
  GridFilterOperator,
  gridDataRowIdsSelector,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Loader } from '../ui';
import { PreloadMeshViewer } from './PreloadMeshViewer';
import { getSuperRecords, exportSuperRecords } from './Network';
import { useNavigate } from 'react-router-dom';
import { AllScansContext } from './AllScansContext';
import { UserModel } from '../account';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import {
  AllScansContainer,
  DataGridContainer,
  StyledToolbarColumnsButton,
  StyledToolbarFilterButton,
  StyledToolbarDensityButton,
  ExportButton,
} from './styles';

export const AllScansScreen = () => {
  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const prevModelRef = useRef<GridFilterModel | null>(null);
  const [scan, setScan] = useState<ScanModel | undefined>(undefined);
  const [selectedFilters, setSelectedFilters] = useState<
    Record<string, boolean>
  >({});
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      id: true,
      featureset_id: true,
      labeled_cobb: true,
      predicted_cobb: true,
      organization_name: true,
      study_site: true,
      excluded_from_training: true,
      status: true,
      created_at: true,
      most_recent_metashape_pip_package_version: true,
      most_recent_mesh_processing_pip_package_version: true,
      show_null_cobbs: false,
    });
  const [incomingPatchRequest, setIncomingPatchRequest] = useState<
    boolean | null
  >(null);
  const [loadTable, setLoadTable] = useState<boolean>(true);
  const [validMeshData, setValidMeshData] = useState<boolean>(false);
  const [validDeviceData, setValidDeviceData] = useState<boolean>(false);

  const handleClick: TRowClickFunction<any> = (
    item: GridRowParams<ScanModel>
  ) => {
    setScan(item.row);
  };

  const getValidScans = useCallback(
    (props: any) => {
      return getSuperRecords(props)
        .then((suc) => {
          return suc;
        })
        .catch((e) => {
          console.error(e.message);
          // very basic way to handle redirect if the user is not authorized.
          if (
            e.message ===
              'You do not have permission to perform this action.' ||
            e.message === 'Connection failed'
          ) {
            navigate('/dashboard');
          }

          return { count: 0, data: [] };
        });
    },
    [navigate]
  );

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const [orderingModel, setOrderingModel] = useState<GridSortModel>([]);
  const [filterModel, setFilteringModel] = useState<GridFilterModel>({
    items: [],
  });

  // tracks which filters are selected
  const trackSelectedFilters = (filterModel: GridFilterModel) => {
    const newSelectedFilters: Record<string, boolean> = {};
    for (let i = 0; i < filterModel.items.length; i++) {
      newSelectedFilters[filterModel.items[i].field] = true;
    }
    setSelectedFilters(newSelectedFilters);
  };

  // function that checks if an actual value within the selected filters has changed
  const checkForFilterChanges = (changedItems: GridFilterItem[]) => {
    if (changedItems.length === 0) {
      return true;
    }

    // first condition checks if the labeled and predicted cobb filters are empty or not
    const hasEmptyLabeledOperator = changedItems.some(
      (item) => item.operator === 'isEmpty' && item.field === 'labeled_cobb'
    );
    const hasEmptyPredictedOperator = changedItems.some(
      (item) => item.operator === 'isEmpty' && item.field === 'predicted_cobb'
    );

    if (hasEmptyLabeledOperator) {
      // Check if '>=' or '<=' operators exist with undefined values
      const hasRangeWithUndefined = changedItems.some(
        (item) =>
          (item.operator === '>=' || item.operator === '<=') &&
          item.field === 'labeled_cobb' &&
          (item.value === undefined || item.value.length === 0)
      );

      if (!hasRangeWithUndefined) {
        return true;
      }
    }

    if (hasEmptyPredictedOperator) {
      // Check if '>=' or '<=' operators exist with undefined values
      const hasRangeWithUndefined = changedItems.some(
        (item) =>
          (item.operator === '>=' || item.operator === '<=') &&
          item.field === 'predicted_cobb' &&
          (item.value === undefined || item.value.length === 0)
      );

      if (!hasRangeWithUndefined) {
        return true;
      }
    }

    for (let i = 0; i < changedItems.length; i++) {
      if (
        changedItems[i].value !== undefined &&
        changedItems[i].value.length > 0
      ) {
        return true;
      }
    }

    return false;
  };

  const params = useMemo(() => {
    setIncomingPatchRequest(null);
    return {
      pagination: {
        page: paginationModel.page + 1,
        size: paginationModel.pageSize,
      },
      ordering: {
        field: orderingModel[0]?.field || 'id',
        direction:
          orderingModel[0]?.sort === 'asc'
            ? OrderingDirection.Asc
            : OrderingDirection.Desc,
      },
      filters: filterModel,
    };
  }, [paginationModel, orderingModel, filterModel]);

  const { data, count, loading } = useListDataRequest<UserModel>(
    getValidScans,
    params,
    incomingPatchRequest,
    setLoadTable,
    setIncomingPatchRequest
  );

  // Add this effect to update the selected scan when data changes
  useEffect(() => {
    if (data && scan) {
      const updatedScan = data.find((item) => item.id === scan.id) as
        | ScanModel
        | undefined;
      if (updatedScan) {
        setScan(updatedScan);
      }
    }
  }, [data, scan]);

  const [rowCountState, setRowCountState] = useState(count || 0);

  const handleColumnVisibilityChange = (
    newModel: GridColumnVisibilityModel
  ) => {
    newModel.show_null_cobbs = false;
    setColumnVisibilityModel(newModel);
  };

  useEffect(() => {
    if (count) {
      setRowCountState(count);
    }
  }, [count]);

  const onPaginationModelChange = (
    data: GridPaginationModel,
    _details: GridCallbackDetails
  ) => {
    setPaginationModel(data);
  };

  const onSortingModelChange = (
    data: GridSortModel,
    _details: GridCallbackDetails
  ) => {
    setOrderingModel(data);
  };

  const onFilteringModelChange = (
    data: GridFilterModel,
    _details: GridCallbackDetails
  ) => {
    trackSelectedFilters(data);
    const prevModel = prevModelRef.current;
    const currentModel = data;

    const resetPaginationObject: GridPaginationModel = {
      page: 0,
      pageSize: paginationModel.pageSize,
    };

    if (prevModel) {
      // easy way to get an array with the changed items
      const changedItems = currentModel.items.filter(
        (item) => !prevModel.items.includes(item)
      );

      // if changes are detected in the filter model - we want to update the state of the filter model which will trigger a new request
      if (checkForFilterChanges(changedItems) === true) {
        setPaginationModel(resetPaginationObject);
        setFilteringModel((prevModel) => ({
          ...prevModel,
          items: [...data.items],
        }));
      }
    }
    // same logic as above - this only occurs on the first filter change
    else if (prevModel === null && currentModel.items) {
      if (checkForFilterChanges(currentModel.items) === true) {
        setPaginationModel(resetPaginationObject);
        setFilteringModel((prevObject) => ({
          ...prevObject,
          items: [...data.items],
        }));
      }
    }

    // Update the ref to the current state for the next render
    prevModelRef.current = currentModel;
  };

  const IDFilterOperators = getGridNumericOperators()
    .filter(({ value }) => ['isAnyOf'].includes(value))
    .map((operator) => {
      if (operator.value === 'isAnyOf') {
        return { ...operator, label: '=' };
      }
      return operator;
    });

  const getCobbFilterOperators = () => {
    return getGridNumericOperators()
      .filter(({ value }) => ['isAnyOf', '>=', '<='].includes(value))
      .map((operator) => {
        if (operator.value === 'isAnyOf') {
          return { ...operator, label: '=' };
        }
        return operator;
      });
  };

  const nameFilterOperators = getGridStringOperators()
    .filter(({ value }) => ['isAnyOf', 'contains'].includes(value))
    .map((operator) => {
      if (operator.value === 'isAnyOf') {
        return { ...operator, label: '=' };
      }
      return operator;
    });

  const semanticVersionFilterOperators = getGridStringOperators()
    .filter(({ value }) => ['isAnyOf'].includes(value))
    .map((operator) => {
      if (operator.value === 'isAnyOf') {
        return { ...operator, label: '=' };
      }
      return operator;
    });

  const isAnyOfOperator = semanticVersionFilterOperators.find(
    (op) => op.value === 'isAnyOf'
  );

  // Custom operator for semantic version filters
  const customSemanticVersionOperator: GridFilterOperator<UserModel, any, any> =
    {
      ...isAnyOfOperator,
      label: '>',
      value: 'greater_than',
      getApplyFilterFn: (filterItem, column) => {
        return (params) => {
          return true;
        };
      },
    };

  const combinedSemanticVersionOperators: GridFilterOperator<
    UserModel,
    any,
    any
  >[] = [...semanticVersionFilterOperators, customSemanticVersionOperator];

  const statusFilterOperators = getGridSingleSelectOperators().filter(
    ({ value }) => ['isAnyOf'].includes(value)
  );

  const deviceTypeFilterOperators = getGridSingleSelectOperators()
    .filter(({ value }) => ['is'].includes(value))
    .map((operator) => {
      if (operator.value === 'is') {
        return { ...operator, label: '=' };
      }
      return operator;
    });

  const columns: GridColDef<UserModel>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 100,
      filterOperators: IDFilterOperators,
    }, // Fixed width as IDs are usually of similar length'
    {
      field: 'patient',
      headerName: 'Patient ID',
      flex: 1,
      minWidth: 100,
      filterOperators: IDFilterOperators,
    },
    {
      field: 'most_recent_featureset_id',
      headerName: 'Featureset ID',
      flex: 1,
      minWidth: 100,
      filterOperators: IDFilterOperators,
    }, // Fixed width as IDs are usually of similar length
    {
      field: 'cleared_for_release',
      headerName: 'Cleared for Release',
      flex: 1,
      minWidth: 150, // Fixed width, assuming the content is either short or similar in length
      filterOperators: getGridBooleanOperators(),
    },
    {
      field: 'labeled_cobb',
      headerName: 'Labeled Cobb',
      flex: 1,
      minWidth: 125,
      filterOperators: getCobbFilterOperators(),
    }, // Flexible width
    {
      field: 'predicted_cobb',
      headerName: 'Predicted Cobb',
      flex: 1,
      minWidth: 125,
      filterOperators: getCobbFilterOperators(),
    }, // Flexible width
    {
      field: 'organization_name',
      headerName: 'Organization',
      flex: 1,
      minWidth: 125,
      filterOperators: nameFilterOperators,
    }, // Flexible width
    {
      field: 'study_site',
      headerName: 'Study Site',
      flex: 1,
      minWidth: 125,
      filterOperators: getGridBooleanOperators(),
    }, // Flexible width
    {
      field: 'excluded_from_training',
      headerName: 'Excluded From Training',
      flex: 1,
      minWidth: 175, // Fixed width, assuming the content is either short or similar in length
      filterOperators: getGridBooleanOperators(),
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: ScanStatusCellRenderer,
      flex: 1,
      minWidth: 150, // Minimum width
      type: 'singleSelect',
      valueOptions: ['Uploaded', 'Processing', 'Completed', 'Failed'],
      filterOperators: statusFilterOperators,
    },
    {
      field: 'device_type',
      headerName: 'Device Type',
      flex: 1,
      minWidth: 125,
      type: 'singleSelect',
      valueOptions: ['ios', 'android'],
      filterOperators: deviceTypeFilterOperators,
      //filterOperators: getGridBooleanOperators(),
    },
    {
      field: 'device_model',
      headerName: 'Device Model',
      flex: 1,
      minWidth: 125,
      filterOperators: nameFilterOperators,
    },
    {
      field: 'device_os',
      headerName: 'Device OS',
      flex: 1,
      minWidth: 125,
      filterOperators: combinedSemanticVersionOperators,
    },
    {
      field: 'app_version',
      headerName: 'App Version',
      flex: 1,
      minWidth: 125,
      filterOperators: combinedSemanticVersionOperators,
    },
    {
      field: 'created_at',
      headerName: 'Scan Date',
      flex: 1,
      minWidth: 175,
      filterable: false,
    }, // Fixed width, assuming standard date formats
    {
      field: 'most_recent_metashape_pip_package_version',
      headerName: 'Metashape Pip Package Version',
      flex: 1,
      minWidth: 175,
      filterOperators: combinedSemanticVersionOperators,
    },
    {
      field: 'most_recent_mesh_processing_pip_package_version',
      headerName: 'Mesh Processing Pip Package Version',
      flex: 1,
      minWidth: 175,
      filterOperators: combinedSemanticVersionOperators,
    },
    {
      field: 'show_null_cobbs',
      headerName: 'Show Null Cobbs',
      filterOperators: getGridBooleanOperators(),
      filterable:
        'labeled_cobb' in selectedFilters ||
        'predicted_cobb' in selectedFilters,
    },
  ];

  // custom filter logc that allows labeled_cobb and predicted_cobb parameters to be added to the filter params at a maximum of 3 times each
  // example why we want this to happen: labeled_cobb >= 20 AND labeled_cobb <= 30 AND labeled_cobb = 25 is a valid filter param
  const filterColumns = ({
    field,
    columns,
    currentFilters,
  }: FilterColumnsArgs) => {
    // remove already filtered fields from list of columns
    const countOccurrences = (arr: string[], val: string) =>
      arr.filter((item) => item === val).length;

    const filteredFields = currentFilters?.map((item) => item.field);
    const labeledCobbCount = countOccurrences(filteredFields, 'labeled_cobb');
    const predictedCobbCount = countOccurrences(
      filteredFields,
      'predicted_cobb'
    );
    return columns
      .filter((colDef) => {
        if (colDef.field === 'labeled_cobb' && labeledCobbCount < 3)
          return true;
        if (colDef.field === 'predicted_cobb' && predictedCobbCount < 3)
          return true;
        return (
          colDef.filterable &&
          (colDef.field === field || !filteredFields.includes(colDef.field))
        );
      })
      .map((column) => column.field);
  };

  // extension of the function above - this function is used to determine which column to add to the filter panel when a new filter is added
  // the same logic of having a maximum of 3 labeled_cobb and predicted_cobb filters applies here
  const getColumnForNewFilter = ({
    currentFilters,
    columns,
  }: GetColumnForNewFilterArgs) => {
    const filteredFields = currentFilters?.map(({ field }) => field);
    const countOccurrences = (arr: string[], val: string) =>
      arr.filter((item) => item === val).length;
    const labeledCobbCount = countOccurrences(filteredFields, 'labeled_cobb');
    const predictedCobbCount = countOccurrences(
      filteredFields,
      'predicted_cobb'
    );
    const columnForNewFilter = columns
      .filter((colDef) => {
        if (colDef.field === 'labeled_cobb' && labeledCobbCount < 3) {
          return colDef.filterable && filteredFields.includes(colDef.field);
        }
        if (colDef.field === 'predicted_cobb' && predictedCobbCount < 3) {
          return colDef.filterable && filteredFields.includes(colDef.field);
        }

        return colDef.filterable && !filteredFields.includes(colDef.field);
      })
      .find((colDef) => colDef.filterOperators?.length);
    return columnForNewFilter?.field ?? null;
  };

  const handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: 'valid_mesh_data' | 'valid_device_data',
    setStateCallback: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const isChecked = event.target.checked;

    setStateCallback(isChecked);

    // Update the filtering model
    setFilteringModel((prevModel) => ({
      ...prevModel,
      items: [
        ...prevModel.items.filter((item) => item.field !== field),
        {
          field: field,
          operator: 'is',
          value: isChecked ? 'true' : 'false',
        },
      ],
    }));
  };

  const handleExport = async () => {
    try {
      setLoadTable(true);
      await exportSuperRecords({
        filters: filterModel,
        ordering: {
          field: orderingModel[0]?.field || 'id',
          direction:
            orderingModel[0]?.sort === 'asc'
              ? OrderingDirection.Asc
              : OrderingDirection.Desc,
        },
      });
      enqueueSnackbar('Export Successful', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Export failed', { variant: 'error' });
    } finally {
      setLoadTable(false);
    }
  };

  const customToolbar = () => {
    return (
      <GridToolbarContainer>
        <StyledToolbarColumnsButton />
        <StyledToolbarFilterButton />
        <StyledToolbarDensityButton />
        <ExportButton
          variant='containedLight'
          onClick={handleExport}
          style={{ cursor: 'pointer' }}
          disabled={loadTable}
        >
          <GetAppIcon />
          <Typography style={{ marginLeft: '0.15vw' }}>Export</Typography>
        </ExportButton>
        <FormGroup row={true} style={{ marginLeft: '0.5rem' }}>
          <FormControlLabel
            control={
              <Switch
                checked={validMeshData}
                onChange={(e) =>
                  handleSwitchChange(e, 'valid_mesh_data', setValidMeshData)
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label='Valid Mesh Data'
          />
          <FormControlLabel
            control={
              <Switch
                checked={validDeviceData}
                onChange={(e) =>
                  handleSwitchChange(e, 'valid_device_data', setValidDeviceData)
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label='Valid Device Data'
          />
        </FormGroup>
      </GridToolbarContainer>
    );
  };

  return (
    <AllScansContext.Provider
      value={{ data, apiRef, loading, setIncomingPatchRequest, setLoadTable }}
    >
      <AllScansContainer>
        <PreloadMeshViewer scan={scan} />
        <DataGridContainer>
          {data ? (
            <DataGridPro
              style={{
                width: '100%',
                overflowX: 'hidden',
                overflowY: 'scroll',
              }}
              slots={{
                toolbar: customToolbar,
                panel: GridPanel,
              }}
              slotProps={{
                filterPanel: {
                  filterFormProps: {
                    filterColumns,
                  },
                  getColumnForNewFilter,
                  logicOperators: [GridLogicOperator.And],
                },
                toolbar: {
                  csvOptions: {
                    getRowsToExport: () => gridDataRowIdsSelector(apiRef),
                  },
                  sx: {
                    '& .MuiButton-root': {
                      background: 'white',
                      color: '#353535',
                    },
                    '& .MuiButton-root:hover': {
                      color: 'black',
                      backgroundColor: '#EFEFEF',
                    },
                  },
                },
                panel: {
                  sx: {
                    '& .MuiButton-root': {
                      background: 'white',
                      color: '#353535',
                    },
                    '& .MuiButton-root:hover': {
                      color: 'black',
                      backgroundColor: '#EFEFEF',
                    },
                    '& .MuiDataGrid-filterFormDeleteIcon': {
                      justifyContent: 'center',
                    },
                    '& .MuiDataGrid-filterFormColumnInput': {
                      paddingRight: '1rem',
                      // width: 'fit-content',
                      minWidth: '8vw',
                    },
                    '& .MuiDataGrid-filterFormOperatorInput': {
                      paddingRight: '1rem',
                    },
                    '& .MuiOutlinedInput-root': {
                      marginTop: '10px',
                    },
                    '& .MuiDataGrid-filterFormValueInput': {
                      marginTop: '0.1rem',
                    },
                    '& .MuiInput-root-MuiSelect-root': {
                      width: '100px',
                    },
                    '& .MuiAutocomplete-inputRoot': {
                      minHeight: '3rem',
                      paddingLeft: '0.5rem',
                    },
                  },
                },
              }}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={handleColumnVisibilityChange}
              apiRef={apiRef}
              onRowClick={handleClick}
              rows={data}
              columns={columns}
              rowCount={rowCountState}
              loading={loadTable}
              pageSizeOptions={[25, 50, 100]}
              paginationModel={paginationModel}
              onPaginationModelChange={onPaginationModelChange}
              paginationMode='server'
              onSortModelChange={onSortingModelChange}
              sortingMode='server'
              onFilterModelChange={onFilteringModelChange}
              filterMode='server'
              filterDebounceMs={500}
              pagination
            />
          ) : (
            <Loader size={50} />
          )}
        </DataGridContainer>
      </AllScansContainer>
    </AllScansContext.Provider>
  );
};
