import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid';

export const AllScansContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 1.5vh;
`;

export const AllScansMeshViewerContainer = styled('div')`
  height: 90vh;
  width: 45vw;
  border-radius: 10px;
  background: #f6f6f6;
  overflow: hidden;
  position: sticky;
  top: 1rem;
`;

export const AllScansMeshViewerSkeleton = styled('div')`
  background: grey;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

export const DataGridContainer = styled('div')`
  width: 100%;
  height: 90vh;
  overflow-y: hidden;
  overflow-x: scroll;
`;

export const StyledToolbarColumnsButton = styled(GridToolbarColumnsButton)({
  background: 'white',
  color: 'black',
  '&:hover': {
    background: '#f0f0f0',
  },
});

export const StyledToolbarFilterButton = styled(GridToolbarFilterButton)({
  background: 'white',
  color: 'black',
  '&:hover': {
    background: '#f0f0f0',
  },
});

export const StyledToolbarDensityButton = styled(GridToolbarDensitySelector)({
  background: 'white',
  color: 'black',
  '&:hover': {
    background: '#f0f0f0',
  },
});

export const StyledToolbarExportButton = styled(GridToolbarExport)({
  background: 'white',
  color: 'black',
  '&:hover': {
    background: '#f0f0f0',
  },
});

export const ExportButton = styled(Button)({
  background: 'white',
  color: 'black',
  boxShadow:
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  '&:hover': {
    background: '#f0f0f0',
  },
});
