import { Button, IconButton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Card, DateInputFF, SelectInputFF, TextInputFF } from '../../../ui';
import { Content, FormInputContainer } from './styles';
import EditIcon from '@mui/icons-material/Edit';
import { Form } from 'react-final-form';
import { usePatientContext } from '../../containers/PatientContainer/usePatientContext';
import { usePatientUpdate, useRemovePatientFromOrg } from '../../hooks';
import { enqueueSnackbar } from 'notistack';
import { ValidationErrors } from 'final-form';
import { format } from 'date-fns';
import { config } from '../../../../config';
import { observer } from 'mobx-react-lite';
import { useCoordinatorStore } from '../../../coordinator/coordinatorStore';
import { RemoveFromOrgModal } from '../RemoveFromOrgModal';
import { useNavigate } from 'react-router-dom';

export const PatientInfoCard = observer(() => {
  const { patient, reload } = usePatientContext();
  const coordinatorStore = useCoordinatorStore();
  const coordinators = coordinatorStore.coordinatorList;
  const [result, isLoadingPatientUpdate, , request] = usePatientUpdate();
  const [
    isLoadingRemovePatientFromOrg,
    ,
    removedPatientFromOrg,
    requestRemovePatientFromOrg,
  ] = useRemovePatientFromOrg();
  const [isEditing, setIsEditing] = useState(false);
  const [removeFromOrgModalOpen, setRemoveFromOrgModalOpen] = useState(false);

  const navigate = useNavigate();
  const handleStartEditing = () => {
    setIsEditing(true);
  };

  const handleReset = () => {
    setIsEditing(false);
  };

  const onSubmit = useCallback(
    (values: any) => {
      values.id = patient.id;
      request(values);
      setIsEditing(false);
    },
    [request, patient.id]
  );

  const validate = useCallback((values: any) => {
    const result: ValidationErrors = {};

    if (!values.first_name) {
      result.first_name = true;
    }
    if (!values.last_name) {
      result.last_name = true;
    }

    if (!values.birthday) {
      result.birthday = true;
    }

    if (!values.coordinator) {
      result.coordinator = true;
    }

    return result;
  }, []);

  useEffect(() => {
    if (result) {
      enqueueSnackbar('Patient updated successfully', { variant: 'success' });
      reload();
    }
    if (removedPatientFromOrg) {
      enqueueSnackbar('Patient removed from organization successfully', {
        variant: 'success',
      });
      navigate('/patients/');
    }
  }, [result, reload, removedPatientFromOrg, navigate]);

  return (
    <>
      <RemoveFromOrgModal
        open={removeFromOrgModalOpen}
        onClose={() => setRemoveFromOrgModalOpen(false)}
        patientId={patient.id}
        requestRemovePatientFromOrg={requestRemovePatientFromOrg}
        isLoadingRemovePatientFromOrg={isLoadingRemovePatientFromOrg}
      />
      <Card
        title='Profile Info'
        rightActions={
          isEditing ? undefined : (
            <IconButton size='small' onClick={handleStartEditing}>
              <EditIcon />
            </IconButton>
          )
        }
      >
        <Content>
          <Form
            onSubmit={onSubmit}
            initialValues={patient}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} onReset={handleReset}>
                <FormInputContainer>
                  <TextInputFF
                    disabled={!isEditing}
                    name='first_name'
                    label='First name'
                    fullWidth
                  />
                  <TextInputFF
                    disabled={!isEditing}
                    name='last_name'
                    label='Last name'
                    fullWidth
                  />

                  {isEditing ? (
                    <DateInputFF
                      fieldProps={{
                        parse: (value) => {
                          const date = new Date(value);
                          if (isNaN(date.getTime())) {
                            return '';
                          }
                          return date ? format(date, config.apiDateFormat) : '';
                        },
                        format: (value) => {
                          // needed to handle date field instead of normal dateTime field from backend
                          if (!value) return '';
                          const [year, month, day] = value.split('-');
                          return new Date(
                            parseInt(year),
                            parseInt(month) - 1,
                            parseInt(day)
                          );
                        },
                      }}
                      name='birthday'
                      label='Date of birth'
                      fullWidth
                    />
                  ) : (
                    <TextInputFF name='age' disabled label='Age' fullWidth />
                  )}
                  <TextInputFF
                    type='number'
                    name='latest_known_cobb'
                    label='Latest radiographic cobb angle'
                    disabled={!isEditing}
                    fullWidth
                  />
                  {isEditing ? (
                    <SelectInputFF
                      name='coordinator'
                      label='Coordinator'
                      fullWidth
                      options={coordinators.map((coordinator) => ({
                        value: coordinator.id,
                        label: `${coordinator.first_name} ${coordinator.last_name}`,
                      }))}
                    />
                  ) : (
                    <TextInputFF
                      disabled={!isEditing}
                      name='coordinator_name' // show the name instead of the id
                      label='Coordinator'
                      fullWidth
                    />
                  )}

                  {isEditing && (
                    <>
                      <div>
                        <Button
                          fullWidth
                          variant='contained'
                          type='submit'
                          disabled={isLoadingPatientUpdate}
                        >
                          Save Changes
                        </Button>
                      </div>
                      <div>
                        <Button
                          fullWidth
                          variant='containedLight'
                          type='reset'
                          disabled={isLoadingPatientUpdate}
                        >
                          Cancel
                        </Button>
                      </div>
                    </>
                  )}
                </FormInputContainer>
              </form>
            )}
          />
          {!isEditing && (
            <div>
              <Button
                fullWidth
                variant='containedLight'
                style={{ marginTop: '3vh' }}
                onClick={() => setRemoveFromOrgModalOpen(true)}
              >
                Remove From Organization
              </Button>
            </div>
          )}
        </Content>
      </Card>
    </>
  );
});
