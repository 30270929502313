import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGridProgressionField } from '../../../patient';
import { DataGridTable, ListContext, CombinedTableModel } from '../../../ui';
import {
  formatNullValues,
  formatMostRecentScanDate,
  TRowClickFunction,
} from '../../../ui/components/DataGridPro/helpers';
import { GridColDef } from '@mui/x-data-grid-pro';
import { ScanStatusCellRenderer } from '../DataGridScanStatusField';
import { ScanModel } from '../../network';

interface ScanDataGridProps {
  height: string;
}

export const ScanDataGrid: FC<ScanDataGridProps> = (props) => {
  const { height } = props;
  const { data, loading } = useContext(ListContext);
  const navigate = useNavigate();

  const onRowClick: TRowClickFunction<any> = (item: ScanModel) => {
    navigate(`/patients/${item.patient}/scans?scanId=${item.id}`);
  };

  const columns: GridColDef<CombinedTableModel>[] = [
    {
      field: 'first_name',
      headerName: 'First Name',
      flex: 1,
      minWidth: 125,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      flex: 1,
      minWidth: 125,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'birthday',
      headerName: 'Date of Birth',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'coordinator_name',
      headerName: 'Coordinator',
      flex: 1,
      minWidth: 125,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'created_at',
      headerName: 'Scan Date',
      flex: 1,
      minWidth: 125,
      valueGetter: (params) => {
        return formatMostRecentScanDate(params.value);
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 125,
      renderCell: ScanStatusCellRenderer,
    },
    {
      field: 'predicted_cobb',
      headerName: 'AI Predicted Cobb Angle',
      flex: 1,
      minWidth: 125,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
    },
    {
      field: 'progression',
      headerName: 'Progression since last scan',
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        return formatNullValues(params.value);
      },
      renderCell: (params) => {
        return (
          <DataGridProgressionField
            record={params.row}
            label='Progression since last scan'
            source='progression'
          />
        );
      },
    },
  ];

  return (
    <DataGridTable
      height={height}
      loading={loading}
      data={data}
      columns={columns}
      tableType='record'
      onRowClick={onRowClick}
    />
  );
};
