import { Instance, t } from 'mobx-state-tree';

export const CoordinatorModel = t.model('CoordinatorModel', {
  id: t.number,
  first_name: t.maybeNull(t.string),
  last_name: t.maybeNull(t.string),
  phone: t.maybeNull(t.string),
  npi_number: t.maybeNull(t.string),
  healthcare_role: t.maybeNull(t.string),
  email_verified: t.boolean,
  country: t.maybeNull(t.string),
  email: t.maybeNull(t.string),
  email_notifications_enabled: t.boolean,
  has_accepted_most_recent_privacy_policy: t.boolean,
  has_accepted_most_recent_terms_and_conditions: t.boolean,
  is_admin: t.boolean,
  is_subscribed: t.boolean,
  language: t.maybeNull(t.string),
  organization: t.maybeNull(t.number),
  organization_name: t.maybeNull(t.string),
  pending_organization_requests: t.maybeNull(t.number),
  phone_notifications_enabled: t.boolean,
  phone_verified: t.boolean,
  show_policy_data: t.boolean,
  subscription_offering: t.maybeNull(t.string),
  user: t.maybeNull(t.number),
  username: t.maybeNull(t.string),
  // unknown types
  most_recent_privacy_policy: t.maybeNull(t.frozen()),
  most_recent_terms_and_conditions: t.maybeNull(t.frozen()),
});

export type CoordinatorType = Instance<typeof CoordinatorModel>;
