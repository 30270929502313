import { FC, ReactNode } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';

export interface SelectInputOption {
  value: any;
  label: any;
}

export type SelectInputProps = SelectProps & {
  label?: string | ReactNode;
  helperText?: string | ReactNode;
  options?: Array<SelectInputOption | string | number>;
};

export const SelectInput: FC<SelectInputProps> = (props) => {
  const { label, error, helperText, fullWidth, options, children, ...rest } =
    props;

  return (
    <FormControl variant='standard' error={error} fullWidth={fullWidth}>
      {label && <InputLabel shrink>{label}</InputLabel>}
      <Select disableUnderline error={error} {...rest} sx={{ height: '100%' }}>
        {children}
        {options?.map((x) => {
          let value;
          let label;
          if (typeof x === 'object' && x !== null) {
            value = x.value;
            label = x.label;
          } else {
            value = x;
            label = x;
          }
          return (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
