import { request, requestList } from '../../../network';
import { PatientModel } from '../../patient/network';
import { ListData, ListDataRequestParams } from '../../ui';

export const getSignupRequests = async (
  params?: ListDataRequestParams
): Promise<ListData<PatientModel>> => {
  const response = await requestList(
    '/api/organization_signup_requests/',
    params
  );

  return response;
};

interface AcceptPatientRequestParams {
  userId: number | string;
  coordinatorId: number | string;
}

export const acceptPatientRequest = async (
  params: AcceptPatientRequestParams
): Promise<any> => {
  if (!params.userId) {
    throw new Error('userId is required');
  }

  const response = await request({
    path: `/api/organization_signup_requests/${params.userId}/`,
    method: 'PATCH',
    body: {
      accepted_in_organization: true,
      coordinator: params.coordinatorId,
    },
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};
interface DeclinePatientRequestParams {
  userId: number | string;
}

export const declinePatientRequest = async (
  params: DeclinePatientRequestParams
): Promise<any> => {
  if (!params.userId) {
    throw new Error('userId is required');
  }

  const response = await request({
    path: `/api/organization_signup_requests/${params.userId}/`,
    method: 'PATCH',
    body: {
      accepted_in_organization: false,
    },
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const getQrCode = async (): Promise<any> => {
  const response = await request({
    path: '/api/generate_organization_qr_code/',
    method: 'GET',
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};
