import { requestList, patchRequest, retriggerScanRequest } from '../../network';
import { ScanModel, FeaturesetValues } from '../scan';
import { ListData, ListDataRequestParams } from '../ui';

export const getSuperRecords = async (
  data?: ListDataRequestParams
): Promise<ListData<ScanModel>> => {
  const response = await requestList('/api/super_records/', data);

  return response;
};

export const exportSuperRecords = async (
  data?: ListDataRequestParams
): Promise<void> => {
  const response = await requestList('/api/super_records/export/', data);

  const link = document.createElement('a');
  const filename = 'super_records_export.csv';
  link.href = response?.url || '';
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(response?.url || '');
};

export const patchSuperRecord = async (
  id: number,
  patchData: Partial<ScanModel> = {}
): Promise<ScanModel> => {
  const response = await patchRequest(`/api/super_records/${id}/`, patchData);

  return response as ScanModel;
};

export const retriggerScan = async (postData: {
  id: number;
}): Promise<{ Success: 'Processing retriggered successfully' }> => {
  const response = await retriggerScanRequest(
    '/api/retrigger_record_processing/',
    postData
  );

  return response;
};

export const patchFeatureSet = async (
  id: number,
  patchData: Partial<FeaturesetValues> = {}
): Promise<FeaturesetValues> => {
  const response = await patchRequest(`/api/feature_set/${id}/`, patchData);

  return response as FeaturesetValues;
};
